import { useState } from "react";

function Faq() {
  const [openCategories, setOpenCategories] = useState([]);

  const toggleCategory = (i) => {
    let position = openCategories.indexOf(i);
    if (position > -1) {
      setOpenCategories(openCategories.filter(item => item !== i));
    }
    else {
      setOpenCategories(openCategories => [...openCategories, i]);
    }
  };

  let categories = [
    {
      name: "Category 1",
      questions: [
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
      ]
    },
    {
      name: "Category 2",
      questions: [
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
      ]
    },
    {
      name: "Category 3",
      questions: [
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
        {
          question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
          answer: `<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>`
        },
      ]
    },
  ];

  let categoriesHTML = [];
  categories.forEach((category, i) => {
    let questionsHTML = [];

    category.questions.forEach((question, i) => {
      questionsHTML.push(<div className="question" key={i}>
          <h3>{question.question}</h3>
          <div className="answer" dangerouslySetInnerHTML={{__html: question.answer}}></div>
        </div>);
    });

    categoriesHTML.push(<div className={"category " + (openCategories.indexOf(i) > -1 ? 'open ' : '')} key={i}>
        <button type="button" className="btn" onClick={() => { toggleCategory(i) }}>{category.name}</button>
        <div className="questions">{questionsHTML}</div>
      </div>);
  });


  return (
    <div className="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h1>Frequently Asked Questions</h1>
            <div className="categories">
              {categoriesHTML}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
