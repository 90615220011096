import Petition from "./Petition";

function DemandAction() {
  return (
    <div className="demand-action">
      <div className="container intro">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h1>They need YOUR vote. So make them work for it.</h1>
            <p>
              Ontario is facing a housing crisis.<br />
              In times like these, it’s not enough to sit on the sidelines. It’s on all of us to fix this mess. Demand change from politicians who are going to want YOUR vote on June 2nd.<br />
              Tell them to cut the **** and stop making excuses.<br />
              Tell them you want your next home built. Now.
            </p>
            <Petition mode="petition" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemandAction;
