import bigTapeTopLeft from "./images/big-tape-top-left.png";
import bigTapeTopRight from "./images/big-tape-top-right.png";

function Petition(props) {

  // set content based on whether this is showing the newsletter or petition screen
  let topMessage,
    buttonTitle,
    checkBoxLabel;
  if (props.mode === "newsletter") {
    topMessage = (<div>
        <h2>Put housing on the <span>ballot!</span></h2>
        <p className="quote">“I’m looking for a place to call home. I’m tired of the excuses, delays, and roadblocks to build my next home. We need a real plan coming out of this election. We need to fix this crisis We need to cut the **** and build more homes.”</p>
      </div>);
    buttonTitle = "I'm in";
    checkBoxLabel = "Sign our petition for one million new homes over the next ten years";
  }
  else if (props.mode === "petition") {
    topMessage = (<div>
        <p className="quote">
          To my Member of Provincial Parliament,<br /><br />
          As you know, the housing shortage is contributing to unprecedented increases in housing prices across the province.<br /><br />
          This places an immense financial strain on middle-class families and is pricing young people out of our large productive cities, and often out of Ontario as a whole.<br /><br />
          There’s no other word for it: We’re in a crisis.<br /><br />
          And we know how to address this crisis. We need to build.<br /><br />
          Economist Mike Moffat at the Smart Prosperity Institute recently issued a report that explains why we need to build at least one million homes over the next 10 years. This is 330,000 more than what we’ve built over the last 10 years.<br /><br />
          I’m writing to ask that you take immediate action to help increase the supply of new housing across the province.<br /><br />
          Sincerely,
        </p>
      </div>);
    buttonTitle = "Demand Action";
    checkBoxLabel = "Join our newsletter to keep track of our progress";
  }

  return (
    <div className="subscribe">
      <div className="top">
        <img src={bigTapeTopLeft} alt="" className="big-tape top-left" />
        <img src={bigTapeTopRight} alt="" className="big-tape top-right" />
        {topMessage}
      </div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="first_name">First Name</label>
            <input type="text" name="first_name" id="first_name" placeholder="Enter your first name" className="form-control" />
          </div>
          <div className="col-md-6">
            <label htmlFor="last_name">Last Name</label>
            <input type="text" name="last_name" id="last_name" placeholder="Enter your last name" className="form-control" />
          </div>
          <div className="col-md-6">
            <label htmlFor="email">Email Address</label>
            <input type="text" name="email" id="email" placeholder="Enter your email address" className="form-control" />
          </div>
          <div className="col-md-6">
            <label htmlFor="postal_code">Postal Code</label>
            <input type="text" name="postal_code" id="postal_code" placeholder="Enter your postal code" className="form-control" />
          </div>
          <div className="col-12">
            <input type="checkbox" value="sign" name="sign" id="sign" />
            <label htmlFor="sign">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
              </span>
              {checkBoxLabel}
            </label>
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary">{buttonTitle}</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Petition;
