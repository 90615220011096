import ontarioHomeBuildersLogo from "./images/ontario-home-builders-logo.png";
import email from "./images/email.svg";
import facebook from "./images/facebook-circle.svg";
import linkedin from "./images/linkedin-circle.svg";
import instagram from "./images/instagram-circle.svg";
import twitter from "./images/twitter-circle.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 footer-col">
            <div className="row">
              <div className="col-lg-6">
                <div className="top">
                  <img src={ontarioHomeBuildersLogo} alt="Ontario Home Builers' Association" className="logo" />
                  <a href="mailto:info@ohba.ca"><img src={email} alt="Email" /><span>info@ohba.ca</span></a>
                </div>
                <p>&copy; Ontario Home Builders' Association. All rights reserved.</p>
              </div>
              <div className="col-lg-6">
                <ul className="social">
                  <li><a href="#" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook" /></a></li>
                  <li><a href="#" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="LinkedIn" /></a></li>
                  <li><a href="#" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram" /></a></li>
                  <li><a href="#" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
