import { useEffect } from "react";

import Petition from "./Petition";

import { ReactComponent as Path1 } from "./images/path-1.svg";
import { ReactComponent as Path2 } from "./images/path-2.svg";
import { ReactComponent as Path3 } from "./images/path-3.svg";
import { ReactComponent as Path4 } from "./images/path-4.svg";
import { ReactComponent as Path5 } from "./images/path-5.svg";
import { ReactComponent as Path6 } from "./images/path-6.svg";
import { ReactComponent as Path7 } from "./images/path-7.svg";
import { ReactComponent as Path8 } from "./images/path-8.svg";
import { ReactComponent as Path9 } from "./images/path-9.svg";
import { ReactComponent as Path10 } from "./images/path-10.svg";
import { ReactComponent as Path11 } from "./images/path-11.svg";
import { ReactComponent as Path12 } from "./images/path-12.svg";
import { ReactComponent as Path13 } from "./images/path-13.svg";
import star from "./images/star.svg";
import ontario from "./images/ontario.png";
import shocked from "./images/shocked.webp";
import money from "./images/money.webp";
import teletubbies from "./images/teletubbies.webp";
import calculations from "./images/calculations.webp";
import hardhat from "./images/hardhat.webp";
import mess from "./images/mess.webp";
import drill from "./images/drill.webp";
import cement from "./images/cement.webp";
import party from "./images/party.webp";
import dunk from "./images/dunk.webp";
import construction from "./images/construction.webp";
import garbage from "./images/garbage.webp";
import cut from "./images/cut.webp";
import regulation from "./images/regulation.webp";
import fingerGuns from "./images/finger-guns.webp";
import vote from "./images/vote.webp";
import celebrate from "./images/celebrate.webp";
import mail from "./images/mail.gif";
import tapeTopRight from "./images/tape-top-right.png";
import tapeTop from "./images/tape-top.png";
import tapeTopLeft from "./images/tape-top-left.png";
import tapeBottomRight from "./images/tape-bottom-right.png";
import tapeBottomLeft from "./images/tape-bottom-left.png";

function Home() {
  useEffect(() => {
    let options = {
      rootMargin: '0px',
      threshold: 0.5
    }

    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('play');
        }
      });
    }, options);

    let paths = document.querySelectorAll('.path-col svg');
    paths.forEach((path) => { observer.observe(path); });
  }, []);

  return (
    <div className="home">
      <div className="container hero">
        <div className="row">
          <div className="col">
            <h1>
              We’re in a<br />
              <span><span>housing crisis!</span></span>
            </h1>
            <p>Scroll down to see why, and what you can do to help.</p>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row path-row">
          <div className="col path-1 path-col">
            <div className="path-container">
              <img src={star} className="star"/>
              <Path1 />
            </div>
            <div className="text-box box-1">
              <span>🏡 The average home in Ontario costs about $1,000,000...</span>
            </div>
            <div className="text-box box-2">
              <span>🤦‍♂️ ...that's over 16x the average income.</span>
            </div>
            <div className="gif">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={shocked} alt="Shocked" />
            </div>
          </div>
        </div>
        <div className="row justify-content-between text-row text-1">
          <div className="col-4">
            <img src={ontario} alt="Ontario" />
          </div>
          <div className="col-7 text-col">
            <p className="big">
              Housing in Ontario is expensive because <span>there’s not enough of it.</span><br />
              In fact, there are fewer homes per capita in Ontario than in any other province.
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-2 path-col">
            <div className="path-container">
              <Path2 />
              <div className="emojis">
                <span>🇨🇦</span>
                <span>🧱</span>
              </div>
            </div>
            <div className="gif">
              <img src={tapeTop} className="tape top"/>
              <img src={money} alt="Money" />
            </div>
          </div>
        </div>
        <div className="row justify-content-between text-row text-2">
          <div className="col-4 gif-col">
            <div className="gif">
              <img src={tapeTopLeft} className="tape top-left"/>
              <img src={tapeBottomRight} className="tape bottom-right"/>
              <img src={teletubbies} alt="Teletubbies" />
            </div>
          </div>
          <div className="col-7 text-col">
            <p className="big">
              To catch up to the provincial average, we would need to build 650,000 new homes overnight. <span>We’re only building 70,000 homes per year on average.</span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-3 path-col">
            <div className="path-container">
              <Path3 />
              <div className="emojis emojis-1">
                <span>🏘</span>
              </div>
              <div className="emojis emojis-2">
                <span>📈</span>
              </div>
            </div>
            <div className="gif">
              <img src={tapeTop} className="tape top"/>
              <img src={calculations} alt="Calculations" />
            </div>
          </div>
        </div>
        <div className="row text-row text-3">
          <div className="col-6 offset-lg-2  text-col">
            <p className="big">
              When we don’t build enough homes, <span>prices rise dramatically</span> and lead to the mess we’re in now.
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-4 path-col">
            <div className="path-container">
              <Path4 />
              <div className="emojis">
                <span>📆</span>
              </div>
            </div>
            <div className="gif gif-1">
              <img src={tapeTop} className="tape top"/>
              <img src={hardhat} alt="Hard Hat" />
            </div>
            <div className="gif gif-2">
              <img src={tapeTop} className="tape top"/>
              <img src={mess} alt="Mess" />
            </div>
          </div>
        </div>
        <div className="row text-row text-4">
          <div className="col-6 offset-lg-3  text-col">
            <p className="big">
              Over the next 10 years, Ontario needs at least one million new homes.<br />
              <span>That’s 330,000 more than we’ve built over the past 10 years.</span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-5 path-col">
            <div className="path-container">
              <Path5 />
              <div className="emojis">
                <span>🏗</span>
              </div>
            </div>
            <div className="gif gif-1">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={drill} alt="Drill" />
            </div>
            <div className="gif gif-2">
              <img src={tapeTop} className="tape top"/>
              <img src={cement} alt="Cement" />
            </div>
          </div>
        </div>
        <div className="row text-row text-5">
          <div className="col-6 offset-lg-2  text-col">
            <p className="extra-big">
              We need to start building. <span style={{'--text': "'Now.'"}}><span>Now.</span></span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-6 path-col">
            <div className="path-container">
              <Path6 />
              <div className="emojis">
                <span>🚧</span>
              </div>
            </div>
            <div className="gif gif-1">
              <img src={tapeTop} className="tape top"/>
              <img src={party} alt="Party" />
            </div>
            <div className="gif gif-2">
              <img src={tapeTop} className="tape top"/>
              <img src={dunk} alt="Dunk" />
            </div>
          </div>
        </div>
        <div className="row text-row text-6">
          <div className="col-7 offset-lg-3  text-col">
            <p className="big">
              Red tape. Endless procedures. NIMBYism. A boondoggle of rules and regulations.<br />
              <span>They all make it impossible to build the housing we need.</span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-7 path-col">
            <div className="path-container">
              <Path7 />
              <div className="emojis">
                <span>🛠</span>
              </div>
            </div>
            <div className="gif">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={regulation} alt="Regulation" />
            </div>
          </div>
        </div>
        <div className="row text-row text-7">
          <div className="col-7 offset-lg-1  text-col">
            <p className="big">
              When we <span>don't build enough</span> housing...
            </p>
            <p className="regular">
              People live farther from where they work and who they love.<br />
              They spend more time in traffic and less with their families.<br />
              They have to wait longer to move out and find their own place.<br />
              Some take on massive debt to afford a fraction of a down payment.<br />
              Some have to choose between their next meal or their next mortgage payment.<br />
              We shouldn’t have to find ourselves in these situations.
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-8 path-col">
            <div className="path-container">
              <Path8 />
              <div className="emojis">
                <span>✂️</span>
              </div>
            </div>
            <div className="gif gif-1">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={construction} alt="Construction" />
            </div>
            <div className="gif gif-2">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={garbage} alt="Garbage" />
            </div>
          </div>
        </div>
        <div className="row text-row text-8">
          <div className="col-5 offset-lg-3  text-col">
            <p className="extra-big">
              We need to cut the ****. <span style={{'--text': "'Now.'"}}><span>Now.</span></span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-9 path-col">
            <div className="path-container">
              <Path9 />
            </div>
            <div className="gif">
              <img src={tapeTop} className="tape top"/>
              <img src={cut} alt="Cut" />
            </div>
          </div>
        </div>
        <div className="row text-row justify-content-end text-9">
          <div className="col-lg-5 text-col">
            <p className="big">
              Change won’t happen<br />
              <span>without you.</span>
            </p>
            <p className="regular">
              In times like these, it’s not enough to sit on a recliner and shout at your tv or smartphone.<br />
              On June 2nd, we need to tell politicians to cut the ****.
            </p>
          </div>
          <div className="col-lg-1"></div>
        </div>
        <div className="row path-row">
          <div className="col path-10 path-col">
            <div className="path-container">
              <Path10 />
              <div className="emojis">
                <span>📋</span>
              </div>
            </div>
            <div className="gif">
              <img src={tapeTopLeft} className="tape top-left"/>
              <img src={tapeBottomRight} className="tape bottom-right"/>
              <img src={fingerGuns} alt="Finger Guns" />
            </div>
          </div>
        </div>
        <div className="row text-row text-10">
          <div className="col-5 offset-lg-1 text-col">
            <p className="big">
              Let’s use our votes to turn them into <span>housing champions.</span>
            </p>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-11 path-col">
            <div className="path-container">
              <Path11 />
              <div className="emojis">
                <span>🗳</span>
              </div>
            </div>
            <div className="gif">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={vote} alt="Vote" />
            </div>
          </div>
        </div>
        <div className="row text-row justify-content-end text-11">
          <div className="col-lg-6 text-col">
            <p className="big">
              We can’t regulate our way out of a housing shortage. We can’t tax our way to housing abundance.
            </p>
            <p className="big">
              <span>We need to build with our ballot.</span>
            </p>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row path-row">
          <div className="col path-12 path-col">
            <div className="path-container">
              <Path12 />
            </div>
            <div className="gif">
              <img src={tapeTopRight} className="tape top-right"/>
              <img src={tapeBottomLeft} className="tape bottom-left"/>
              <img src={celebrate} alt="Celebrate" />
            </div>
          </div>
        </div>
        <div className="row text-row justify-content-center text-12">
          <div className="col-6 text-col">
            <p className="extra-big">
              <span style={{'--text': "'Vote'"}}><span>Vote</span></span> to build new neighbourhoods and communities.
            </p>
            <p className="extra-big">
              <span style={{'--text': "'Vote'"}}><span>Vote</span></span> to grow our skilled labour force.
            </p>
            <p className="extra-big">
              <span style={{'--text': "'Vote'"}}><span>Vote</span></span> to stabilize housing prices.
            </p>
            <p className="extra-big">
              <span style={{'--text': "'Vote'"}}><span>Vote</span></span> to cut **** and get Ontario building.
            </p>
          </div>
          <div className="gif">
            <img src={tapeTop} className="tape top"/>
            <img src={mail} alt="Mail" />
          </div>
          <div className="emojis emojis-1">
            <span>🏡</span>
          </div>
          <div className="emojis emojis-2">
            <span>👪</span>
          </div>
        </div>
        <div className="row path-row">
          <div className="col path-13 path-col">
            <div className="path-container">
              <Path13 />
            </div>
          </div>
        </div>
        <div className="row text-row justify-content-center text-13">
          <div className="col-6 text-col">
            <p className="big">
              Housing is on the ballot.<br />
              <span>You’re going to make sure of that</span>
            </p>
            <p className="regular">
              Ontario’s housing crisis won’t fix itself unless we act.<br />
              This election is about demanding real solutions to fix this mess.<br />
              We can’t regulate our way out of a housing shortage. We can’t tax our way to housing abundance. Ontario needs to build at least one million new homes over the next 10 years. And of those homes can be yours.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Petition mode="newsletter"/>
            <div className="counter">
              <h3>Over <span>XX,XXX</span> people have taken the pledge to demand action on housing.</h3>
              <div className="progress"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
