import { Routes, Route } from "react-router-dom";
import Home from './Home';
import DemandAction from './DemandAction';
import Faq from './Faq';
import Header from './Header';
import Footer from './Footer';

import "./styles/App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/action" element={<DemandAction />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
