import { Link, NavLink } from "react-router-dom";
import { useEffect } from "react";

import logo from './images/cut-the-logo.svg';

function Header() {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 100) {
        document.querySelector('header.header').classList.add('scroll');
      }
      else {
       document.querySelector('header.header').classList.remove('scroll'); 
      }
    }, {passive: true});
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col">
            <Link to="/"><img src={logo} alt="Cut the ****" /></Link>
          </div>
          <div className="col">
            <nav>
              <ul>
                <li><NavLink to="/action">Demand Action</NavLink></li>
                <li><NavLink to="/faq">Essential Information</NavLink></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
